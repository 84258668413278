@import "../../styles/_chunk";

$primary-core-selected: #49341e;
$primary-core-selected-light-theme: #ffdfb1;

.MysteryKeySelectionButtons {
  margin-bottom: 1.5rem;

  .MysteryKeySelectionButtons__numberOfKeysMessage {
    margin-top: -0.5rem;
    padding-bottom: 0.25rem;
  }

  .MysteryKeySelectionButtons__keysButtonContainer {
    display: flex;
    align-items: center;
  }

  .MysteryKeySelectionButtons__keysButton {
    border: 1px solid $grey-5;
    color: map-get($dark-theme, font-color);
    font-weight: 400;
    margin: 0;
    padding: 0;
    width: 67px;
    height: 35px;
    margin-right: 6px;
    border-radius: $border-radius-sm;
    background: none;

    @include theme(light) {
      color: map-get($light-theme, font-color);
    }

    &:hover {
      border-color: $white;

      @include theme(light) {
        border-color: $grey-7;
      }
    }

    &:focus {
      outline: none !important;
    }

    &--selected {
      @include brand-property(border-color, brand-core);

      border-width: 2px;
      background-color: $primary-core-selected;

      @include theme(light) {
        background-color: $primary-core-selected-light-theme;
      }

      &:hover {
        @include brand-property(border-color, brand-core);

        @include theme(light) {
          @include brand-property(border-color, brand-core);
        }
      }
    }
  }
}
